<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-2 label-approval">Data solicitação: </div>
            <div class="col-10 content-approval"> {{ transaction?.date_formated }} </div>
          </div>
          <div class="row">
            <div class="col-2 label-approval">Usuário: </div>
            <div class="col-10 content-approval">{{ transaction?.user_account?.user?.name }}</div>
          </div>
          <div class="row">
            <div class="col-2 label-approval">Email: </div>
            <div class="col-10 content-approval">{{ transaction?.user_account?.user?.email }}</div>
          </div>
          <div class="row">
            <div class="col-2 label-approval">Valor: </div>
            <div class="col-10 content-approval">R$ {{ transaction?.ammount_formated }}</div>
          </div>
          <div class="row">
            <div class="col-2 label-approval">Banco: </div>
            <div class="col-10 content-approval">{{ transaction?.bank_account?.bank?.name }}</div>
          </div>
          <div class="row">
            <div class="col-2 label-approval">Agência / Conta: </div>
            <div class="col-10 content-approval">{{ transaction?.bank_account?.agency }} / {{ transaction?.bank_account?.number }}</div>
          </div>
          <div class="row">
            <div class="col-2 label-approval">Chave PIX: </div>
            <div class="col-10 content-approval">{{ transaction?.bank_account?.pix }}</div>
          </div>
          <br/>
          <div class="row">
            <div class="col-4 content-approval"><base-input :required="true" label="Token" v-model="token"></base-input></div>
          </div>
          <div class="row">
            <!-- botoes -->
            <div class="col-12 mt-3">
              <base-button :disabled="disable"  @click="sendData(true)" type="success" class="ml-3">Aprovar</base-button>
              <base-button :disabled="disable" @click="sendData(false)" type="danger">Reprovar</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import swal from 'sweetalert2'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
export default {
  data: () => ({
    token: null,
    disable: false,
    transaction: {
      date_formated: ''
    }
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.transaction = (await this.$http.get(`transactions/approval/detail/${id}`)).data.data

        console.log('transação iD', this.transaction)
      }
    },
    async sendData (approved) {
      this.disable = true
      if (this.validate()) {
        let status
        let message
        try {
          status = (await this.$http.post('transactions/approval', {
            transaction_id: this.transaction.id,
            approval: approved,
            token: this.token
          })).status
        } catch (e) {
          status = e.response.status
          message = e.response.data.message
        }
        if (status === 200) {
          this.disable = false
          swal.fire({
            title: 'Salvo!',
            text: 'Operação realizada com sucesso!',
            showConfirmButton: false,
            timer: 5000
          })
          this.goBack()
        } else {
          this.disable = false
          swal.fire({
            title: 'Erro!',
            text: message,
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        this.disable = false
        swal.fire({
          title: 'Erro!',
          text: 'Por favor, informe o token MFA para validação.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    validate () {
      if (!this.token || !this.token.trim()) return false
      return true
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
